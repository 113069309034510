import React from 'react';
import PropTypes from 'prop-types';

import SectionEvents from '../community/_section-events';

import SandboxLayout from './_layout';

// How to add a new sandbox page:
// Follow the instructions on ./_layout.js

const SandboxConf = ({ location }) => {
  return (
    <SandboxLayout location={location} title='Sandbox'>
      <SectionEvents />
    </SandboxLayout>
  );
};

export default SandboxConf;

SandboxConf.propTypes = {
  location: PropTypes.object
};
