import styled, { css } from 'styled-components';
import { Heading } from '@devseed-ui/typography';

import { glsp, media } from '@devseed-ui/theme-provider';

import UniversalGridder from './universal-gridder';
import { leading } from './typography/leading';
import { SupHeading } from './typography/supheading';
import { stripe } from './motifs';

export const Section = styled(UniversalGridder).attrs({ as: 'section' })`
  display: grid;
  grid-row-gap: ${glsp(2)};

  ${media.mediumUp`
    grid-row-gap: ${glsp(4)};
  `}

  ${media.largeUp`
    grid-row-gap: ${glsp(6)};
  `}

  ${media.xlargeUp`
    grid-row-gap: ${glsp(8)};
  `}
`;

export const SectionLead = styled.div`
  ${leading};
`;

const renderSectionLeadLayout = ({ leadLayout }) => {
  switch (leadLayout) {
    case 'aside':
      return css`
        grid-row: 2;
        grid-column: content-start / content-end;

        ${media.mediumUp`
          grid-column: content-start / content-end;
          padding: ${glsp(0.5, 0)};
        `}

        ${media.largeUp`
          grid-row: 1;
          grid-column: content-8 / content-end;
        `}
      `;
    case 'column':
      return css`
        grid-row: 2;
        grid-column: content-start / content-end;

        ${media.mediumUp`
          grid-column: content-start / content-end;
          column-count: 2;
          column-gap: ${glsp(2)};
        `}

        ${media.largeUp`
          grid-column: content-start / content-end;
        `}
      `;
    // Default bottom
    default:
      return css`
        grid-row: 2;
        grid-column: content-start / content-end;

        ${media.mediumUp`
          grid-column: content-start / content-end;
        `}

        ${media.largeUp`
          grid-column: content-start / content-7;
        `}
      `;
  }
};
const sectionHeaderAttrs = (props) => ({
  as: 'header',
  grid: props.grid || {
    smallUp: ['content-start', 'content-end'],
    mediumUp: ['content-start', 'content-end'],
    largeUp: ['content-start', 'content-end']
  }
});
export const SectionHeader = styled(UniversalGridder).attrs(sectionHeaderAttrs)`
  grid-row: 1;
  grid-row-gap: ${glsp(1)};
  align-items: end;

  ${media.smallUp`
    grid-row-gap: ${glsp(2)};
  `}

  ${media.largeUp`
    grid-row-gap: ${glsp(4)};
  `}

  ${SectionLead} {
    ${renderSectionLeadLayout}
  }
`;

export const SectionHeadline = styled.div`
  grid-column: content-start / content-end;
  display: flex;
  flex-flow: column nowrap;

  ${media.largeUp`
    grid-column: content-start / content-7;
  `}
`;

export const SectionTitle = styled(Heading).attrs({ as: 'h1' })`
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 0;

  ${media.mediumUp`
    font-size: 3rem;
    line-height: 3.5rem;
  `}

  ${media.largeUp`
    font-size: 4rem;
    line-height: 4.5rem;
  `}
`;

export const SectionSuptitle = styled(SupHeading).attrs({
  as: 'p',
  variation: 'primary'
})`
  order: -1;
  margin-bottom: ${glsp()};

  ${media.mediumUp`
    margin-bottom: ${glsp(2)};
  `}
`;

export const SectionBody = styled.div`
  grid-row: 2;
  display: grid;
  grid-column: full-start / full-end;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-row-gap: ${glsp(2)};

  ${media.mediumUp`
    grid-row-gap: ${glsp(4)};
  `}

  ${media.largeUp`
    grid-row-gap: ${glsp(6)};
  `}

  ${media.xlargeUp`
    grid-row-gap: ${glsp(8)};
  `}
`;

export const SectionActions = styled.div`
  grid-column: span 4;
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: ${glsp(1)};

  ${media.smallUp`
    grid-auto-columns: min-content;
  `}

  ${media.mediumUp`
    grid-column: span 8;
  `}

  ${media.largeUp`
    grid-column: span 12;
  `}

  > * {
    min-width: 12rem;

    ${media.smallUp`
      grid-row: 1;
    `}
  }
`;

// // // // // // // // // // // // // // // // // // // // // // // // // // //
// SectionCardGroup                                                           //
// Section that displays the cards on the right and title on the left. Has 2  //
// motifs.
// TODO: Review: correct file and naming
//                                                                            //

export const sectionCardGroupHeaderGrid = {
  smallUp: ['content-start', 'content-end'],
  mediumUp: ['content-start', 'content-end'],
  largeUp: ['content-start', 'content-5']
};

export const SectionCardGroup = styled(Section)`
  position: relative;
  z-index: 2;

  &::before {
    ${stripe};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    grid-row: 2;
    grid-column-start: full-start;
    grid-column-end: span content-3;
    top: ${glsp(8)};

    ${media.smallUp`
      grid-column-end: content-3;
      top: ${glsp(12)};
    `}

    ${media.mediumUp`
      grid-column-end: content-7;
      top: ${glsp(16)};
    `}

    ${media.largeUp`
      grid-row: 1;
      grid-column-end: content-7;
      top: ${glsp(20)};
    `}
  }

  &::after {
    ${stripe};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    grid-row: 2;
    grid-column-start: content-2;
    grid-column-end: span full-end;
    top: ${glsp(20)};

    ${media.smallUp`
      top: ${glsp(28)};
    `}

    ${media.mediumUp`
      grid-column-start: content-3;
      top: min(${glsp(36)}, calc(100% - 8rem));
    `}

    ${media.largeUp`
      grid-row: 1;
      grid-column-start: content-7;
      top: min(${glsp(46)}, calc(100% - 12rem));
    `}
  }

  ${SectionHeader} {
    ${media.largeUp`
      align-items: start;
    `}
  }

  ${SectionHeadline} {
    grid-column: content-start / span 4;

    ${media.largeUp`
      grid-column: content-start / span 8;
    `}

    ${media.largeUp`
      grid-column: content-start / span 4;
    `}

    ${media.xlargeUp`
      grid-column: content-start / span 3;
    `}
  }

  ${SectionBody} {
    position: relative;
    z-index: 2;

    ${media.smallDown`
      padding: ${glsp(0, 1)};
    `}

    ${media.mediumUp`
      grid-column: content-start / content-end;
    `}

    ${media.largeUp`
      grid-row: 1;
      grid-column: content-5 / span 8;
    `}
  }
`;
