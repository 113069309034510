import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { glsp } from '@devseed-ui/theme-provider';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageLead,
  InpageBody
} from '../../styles/inpage';

import Constrainer from '../../styles/constrainer';

const InpageNavigation = styled.nav`
  display: flex;
`;

const InpageNavigationInner = styled(Constrainer)`
  padding-top: ${glsp(2)};
  padding-bottom: ${glsp(2)};
  display: flex;

  ul {
    display: flex;

    li:not(:last-child) {
      margin-right: ${glsp(2)};
    }
  }
`;

const SandboxBody = styled(InpageBody)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: ${glsp(4)};
  padding-top: ${glsp(4)};
  padding-bottom: ${glsp(4)};
`;

// How to add a new sandbox page.
// 1) Create a component with the code inside the sandbox folder.
// 2) Add an entry to the sandboxPages array, with a name for the tab and url.
//    The url must match the file name.
// 3) Profit!
export const sandboxPages = [
  {
    name: 'Prose',
    url: ''
  },
  {
    name: 'Buttons',
    url: '/buttons'
  },
  {
    name: 'Colors',
    url: '/colors'
  },
  {
    name: 'Media',
    url: '/media'
  },
  {
    name: 'Blocks',
    url: '/blocks'
  },
  {
    name: 'Grid',
    url: '/grid'
  },
  {
    name: 'Events',
    url: '/events'
  }
];

const SandboxLayout = ({ title, location, children }) => {
  return (
    <Layout location={location} title={title}>
      <Inpage>
        <InpageHeader leadLayout='aside'>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>Sandbox</InpageTitle>
            </InpageHeadline>
            <InpageLead>
              <p>Testing UI components and layout features.</p>
            </InpageLead>
          </InpageHeaderInner>
          <InpageNavigation>
            <InpageNavigationInner>
              <ul>
                {sandboxPages.map((page) => (
                  <li key={page.url}>
                    <Link
                      exact
                      activeClassName='active'
                      to={`/sandbox${page.url}`}
                    >
                      {page.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </InpageNavigationInner>
          </InpageNavigation>
        </InpageHeader>
        <SandboxBody>{children}</SandboxBody>
      </Inpage>
    </Layout>
  );
};

export default SandboxLayout;

SandboxLayout.propTypes = {
  title: T.string,
  location: T.object,
  children: T.node
};
